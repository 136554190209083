import Vue from 'vue';
import "./css/main.scss";
import Components from "./components";
import VueAwesomeSwiper from 'vue-awesome-swiper'
import axios from 'axios';
import EventBus from './EventBus'
import $ from 'jquery';
require('jquery-serializejson');

// import style
import 'swiper/css/swiper.css'

Vue.use(VueAwesomeSwiper)
// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
let vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty('--vh', `${vh}px`);


if ($("#app").length) {
  new Vue({
    el: "#app",
    delimiters: ["[[", "]]"],
    components: {
     LocalSwiper: VueAwesomeSwiper.swiper,
     LocalSlide: VueAwesomeSwiper.swiperSlide,
   },
   data() {
    return {
      csrfName: window.csrfTokenName,
      csrfToken: window.csrfTokenValue,
    }
  },
  });
}

// $( ".purchasableId" ).change(function(e) {
//   $(".purchasableInfo").hide();
//   $("#"+($(this).find(":selected").data('info'))).toggle();
// });
